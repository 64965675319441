import React from "react";
import Header from "./template/Header";
import Footer from "./template/Footer";
import { useState,useEffect } from "react";
import { Link,useParams } from "react-router-dom";
export default function Product(){
    const {productcat} = useParams();
	const ApiUrl = process.env.REACT_APP_API_URL;
	const [Data, setData] = useState([]);
    const [CatData, setCatData] = useState([]);
    
    const getpdata = async (e) => {
		await fetch(ApiUrl+'GetProducts', {
		  method: "GET",
		  mode:'cors'
		}).then(res => res.json()).then(data => {
		  setData(data.data);
			})
	  };
      const getcatdata = async (e) => {
		await fetch(ApiUrl+"GetCategory", {
		  method: "GET",
		  mode:'cors'
		}).then(res => res.json()).then(data => {
            setCatData(data.data);
			})
	  };
	  useEffect(() => {
		getpdata();
		 }, []);
         useEffect(() => {
            getcatdata();
             }, []);
		 console.log(Data.id)
	return (
    <div>
	<Header/>
    <section className="section-padding">
		<div className="container">
        <div className="row">
            <div className="col-lg-4 order-lg-first order-last">
					
					
					
					<div className="sidebar-widgets">
						<h2>Categories</h2>
						<ul>
                            {CatData.length ? CatData.map((Catelem) => {
                                return (
							<li><Link to={`${process.env.REACT_APP_FRONT_URL}product/${Catelem.seo_url}`} onClick={getpdata}><i className="fas fa-angle-double-right"></i>  {Catelem.title}  </Link></li>
                            )
                            }) : <span>Please Wait...........</span> } 
						</ul>
					</div>
					
					
					
					
				</div> 
            {Data.length ? Data.map((elem) => {
               
                return (<>
				<div className="col-lg-3 col-md-4 mb-30">
					<div className="blog-item">
						<div className="thumbnail">
							<Link to={`${process.env.REACT_APP_FRONT_URL}product-detail/${elem.p_url}`}>
								<img src={`${process.env.REACT_APP_API_URL}/assets/Product/${JSON.parse(elem.img)[0]}`} alt="blog" />
							</Link>
							<div class="content"><h2><Link to={`${process.env.REACT_APP_API_URL}product-detail/${elem.p_url}`}>{elem.p_name}</Link></h2></div>
						</div>
						
					</div>
				</div>
                </>
                )
           
            
            }) : <span>Product not Available</span> }
				
			</div>
			
		</div>
	</section>
	<Footer />
	 </div>
  );
}