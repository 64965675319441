import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import Header from "./template/Header";
import Footer from "./template/Footer";
import { propTypes } from "react-bootstrap/esm/Image";
export default function Home(props){
	return (
    <div>
		
	<Header setProgress={props.setProgress}/>
<Carousel >
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="assets/img/slider1.png"
          alt="First slide"
        />
       
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="assets/img/slider2.png"
          alt="Second slide"
        />

        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="assets/img/slider3.png"
          alt="Third slide"
        />

        
      </Carousel.Item>
    </Carousel>
	
	<div className="pt-20 pb-20">
		<div className="container">
			<div className="row mb-30">
				<div className="col-sm-6 align-self-center">
					<div className="section-headding-title">
						<h2>Popular Category</h2>
					</div>
				</div>
				
			</div>
			<div className="row">
				<div className="col-lg-12">
					<div className="pp_cate_list">
						<ul>
							<li>
								<a href="shop.html">
									<img src="assets/img/cate/1.png" alt="cate"/>
									<span>Tablets</span>
								</a>
							</li>
							<li>
								<a href="shop.html">
									<img src="assets/img/cate/2.png" alt="cate"/>
									<span>Capsules</span>
								</a>
							</li>
							<li>
								<a href="shop.html">
									<img src="assets/img/cate/3.png" alt="cate"/>
									<span>Softgel Capsules</span>
								</a>
							</li>
							<li>
								<a href="shop.html">
									<img src="assets/img/cate/4.png" alt="cate"/>
									<span>Infusions</span>
								</a>
							</li>
							<li>
								<a href="shop.html">
									<img src="assets/img/cate/5.png" alt="cate"/>
									<span>Injections</span>
								</a>
							</li>
							<li>
								<a href="shop.html">
									<img src="assets/img/cate/6.png" alt="cate"/>
									<span>Sachets</span>
								</a>
							</li>
							<li>
								<a href="shop.html">
									<img src="assets/img/cate/7.png" alt="cate"/>
									<span>External Preparations</span>
								</a>
							</li>
							<li>
								<a href="shop.html">
									<img src="assets/img/cate/8.png" alt="cate"/>
									<span>Protein Powder</span>
								</a>
							</li>
							<li>
								<a href="shop.html">
									<img src="assets/img/cate/9.png" alt="cate"/>
									<span>Ayurvedic</span>
								</a>
							</li>
							<li>
								<a href="shop.html">
									<img src="assets/img/cate/10.png" alt="cate"/>
									<span>Energy Drink</span>
								</a>
							</li>
							<li>
								<a href="shop.html">
									<img src="assets/img/cate/11.png" alt="cate"/>
									<span>Liquid</span>
								</a>
							</li>
							<li>
								<a href="shop.html">
									<img src="assets/img/cate/12.png" alt="cate"/>
									<span>Nasal Drops</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<section className="pt-20 pb-50">
		<div className="container">
			<div className="row">
				<div className="col-lg-9">
					<div className="deal-product-sec h-100" style={{height:"95% !important"}}>
						<h2 className="d_title"> About Us </h2>
						<div className="deal_product_slider owl-carousel">
							
							<div className="deal_p_item">
								<div className="row">
									
									<div className="col-md-12 align-self-center">
										<div className="content">
											
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to <a href="#" className="button-1" style={{padding:" 5px 10px"}}>Read More</a></p>
											
										</div>
									</div>
								</div>
							</div>
							
							
						</div>
					</div>
				</div>
				<div className="col-lg-3">
					<div className="d_top_rated_p h-100" style={{height:"95% !important"}}>
						<h2 className="d_title">Download </h2>
						<div className="top-Reated_p_full">
							<div className="tp_item_style">
								
								<div className="content">
<h4><a href="#" className="button-1" style={{color:"white"}}>Product Price List</a></h4>
									
								</div>
							</div>
							<div className="tp_item_style">
								
								<div className="content">
									<h4><a href="#" className="button-1" style={{color:"white"}}>Product Catolog</a></h4>
									
								</div>
							</div>
							<div className="tp_item_style">
								
								<div className="content">
									<h4><a href="#" className="button-1" style={{color:"white"}}>Product Images</a></h4>
									
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	
	<section className="pt-30 pb-30">
		<div className="container">
			<div className="shipping-item-full">
				<div className="row">
					<div className="col-lg-3 col-md-6 mb-30">
						<div className="shipping-item">
							<div className="iocn">
								<i className="bi bi-truck"></i>
							</div>
							<div className="content">
								<h3 className="Shipping" style ={{
    fontSize : "16px",
    lineHeight: "1.2",
    fontWeight: "600",
    paddingBottom: "5px",
	marginTop :"15px"
}}> Same Day Dispatch</h3>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 mb-30">
						<div className="shipping-item">
							<div className="iocn">
								<i className="fab fa-pagelines"></i>
							</div>
							<div className="content">
								<h3 className="Shipping" style ={{
    fontSize : "16px",
    lineHeight: "1.2",
    fontWeight: "600",
    paddingBottom: "5px",
	marginTop :"15px"
}}>   500+ Product Range</h3>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 mb-30">
						<div className="shipping-item">
							<div className="iocn">
								<i className="fas fa-dollar-sign"></i>
							</div>
							<div className="content">
								<h3 className="Shipping" style ={{
    fontSize : "16px",
    lineHeight: "1.2",
    fontWeight: "600",
    paddingBottom: "5px",
	marginTop :"15px"
}}> Competitive price</h3>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 mb-30">
						<div className="shipping-item">
							<div className="iocn">
								<i className="bi bi-shield-lock"></i>
							</div>
							<div className="content">
								<h3 className="Shipping" style ={{
    fontSize : "16px",
    lineHeight: "1.2",
    fontWeight: "600",
    paddingBottom: "5px",
	marginTop :"15px"
}}> 10+ Year of Experience</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div className="pt-20">
			<div className="container">
			<div className="row mb-30">
				<div className="col-sm-6 align-self-center">
					<div className="section-headding-title">
						<h2>Our Services</h2>
					</div>
				</div>
				
			</div>
				<div className="row">
					<div className="col-md-6 mb-20" >
<div className="shipping-item" style={{background:"#6DCD4D",padding:"10px"}}>
							<div className="iocn">
<img src="assets/img/power-plant.png" alt="banner" style={{width:"100px"}} />
							</div>
							<div className="content">
								<h3 className="ShippingBanner">Third Party/Contract Manufacturing</h3>
							</div>
						</div>
						
					</div>
					<div className="col-md-6 mb-20" >
						
						<div className="shipping-item" style={{background:"#6DCD4D",padding:"10px"}}>
							<div className="iocn">
								<img src="assets/img/third-party.png" alt="banner" style={{width:"100px"}}/>
							</div>
							<div className="content">
								<h3 className="ShippingBanner">PCD Pharma  Franchise</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	
	
	<section className="pt-20 pb-20">
		<div className="container">
			<div className="row mb-30">
				<div className="col-sm-6 align-self-center">
					<div className="section-headding-title">
						<h2>Latest Blog & News</h2>
					</div>
				</div>
				
			</div>
			<div className="row">
				<div className="col-lg-4 col-md-6 mb-30">
					<div className="blog-item">
						<div className="thumbnail">
							<a href="single.html">
								<img src="http://cospexpharma.com/assets/blog/ddb0906d45fd214ae12735efc28bcdb8.jpg" alt="blog"className="BlogImg"/>
							</a>
							<div className="date">
								<span><i className="fas fa-calendar-alt"></i> 19 Feb 2023</span>
							</div>
						</div>
						<div className="content">
							<h2><a href="single.html">VITAMIN C: WHY IS IT IMPORTANT?</a></h2>
							<p>Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor...</p>
							
						</div>
					</div>
				</div>
				<div className="col-lg-4 col-md-6 mb-30">
					<div className="blog-item">
						<div className="thumbnail">
							<a href="single.html">
								<img src="http://cospexpharma.com/assets/blog/063ca53af1085abec934a617681b0a60.jpg" alt="blog"className="BlogImg"/>
							</a>
							<div className="date">
								<span><i className="fas fa-calendar-alt"></i> 19 Jan 2023</span>
							</div>
						</div>
						<div className="content">
							<h2><a href="single.html">World Hepatitis Day 2020</a></h2>
							<p>Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor...</p>
							
						</div>
					</div>
				</div>
				<div className="col-lg-4 col-md-6 mb-30">
					<div className="blog-item">
						<div className="thumbnail">
							<a href="single.html">
								<img src="http://cospexpharma.com/assets/blog/f1e26a5956f1b711abe520667a1d7b58.jpg" alt="blog"className="BlogImg"/>
							</a>
							<div className="date">
								<span><i className="fas fa-calendar-alt"></i> 19 Nov 2022</span>
							</div>
						</div>
						<div className="content">
							<h2><a href="single.html">6 harmful effects of lack of sleep — and why it's unhealthy.</a></h2>
							<p>Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor...</p>
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<Footer />
	 </div>
  );
}