import React from "react";
import { Link } from "react-router-dom";
import {Nav, Navbar, Container, NavDropdown} from 'react-bootstrap';  
export default function Header(props){
	return (
    <div>
		
		<header className="header">
		
		<div className="header-top">
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						<div className="hl_top-left">
							<span><i className="bi bi-phone"></i> +91 98884 63210</span>
							<span><i className="bi bi-envelope"></i> sarthilifesciences@gmail.com</span>
							<span><i className="bi bi-map"></i> <a href="https://goo.gl/maps/s2BxZaKKwZFpjNk5A" style={{color:"white"}}>Locate Us</a></span>
						</div>
					</div>
					<div className="col-lg-6">
						
						<div className="top-social">
							<span><a href="#" title="Facebook"><i className="fab fa-facebook-f"></i></a></span>
							<span><a href="#" title="Twitter"><i className="fab fa-twitter"></i></a></span>
							<span><a href="#" title="Instagram"><i className="fab fa-instagram"></i></a></span>
							<span><a href="#" title="Vimeo"><i className="fab fa-vimeo-v"></i></a></span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="header-bottom sticky-header bg-white">
			<div className="container">
				<div className="row">
					
					<Navbar  expand="md">  
    <Container >  
      <Navbar.Brand href="#home" className="col-md-2"><Link to="/">
								<img src={`${process.env.REACT_APP_FRONT_URL}assets/img/logo.png`} alt="logo"/>
							</Link></Navbar.Brand>  
      <Navbar.Toggle aria-controls="basic-navbar-nav" />  
      <Navbar.Collapse id="basic-navbar-nav">  
        <Nav className="me-auto col-md-10" >  
          <Link to="/" style={{padding:"38px 20px"}}>Home</Link>  
          <Link to="/about-us" style={{padding:"38px 20px"}}>About Us</Link>  
		  <Link to="/product-list" style={{padding:"38px 20px"}}>Product</Link>  
          <NavDropdown title="Services" id="basic-nav-dropdown" style={{padding:"30px 20px"}}>  
		  <NavDropdown.Item> <Link to="/third-party-manufacturing">Third Party/Contract Manufacturing</Link>  </NavDropdown.Item> 
            <NavDropdown.Item> <Link to="/pharma-pcd">PCD Pharma Franchise</Link>  </NavDropdown.Item>  
           </NavDropdown>  
		  <Link to="/blog" style={{padding:"38px 20px"}}>Blog</Link>  
		  <Link to="/contact-us" style={{padding:"38px 20px"}}>Contact Us</Link>  
        </Nav>  
      </Navbar.Collapse>  
    </Container>  
  </Navbar>  
				</div>
			</div>
		</div>
	</header>
	
   
	 </div>
  );
}