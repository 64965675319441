import React from "react";
import Header from "./template/Header";
import Footer from "./template/Footer";
import {useParams} from 'react-router-dom';
import { useState,useEffect } from "react";
export default function ProductLisitng(){
	const {product} = useParams();
	const ApiUrl = process.env.REACT_APP_API_URL;
	const [Data, setData] = useState([]);
	const Product_image='';
	const getpdata = async (e) => {
		await fetch(ApiUrl+"GetProducts/"+product, {
		  method: "GET",
		  mode:'cors'
		}).then(res => res.json()).then(data => {
		  setData(data.data);
			})
	  };
	  useEffect(() => {
		getpdata();
		 }, []);
		 
	return (
    <div>
	<Header/>
<section className="section-padding">
		<div className="container">
			<div className="row">
				{(Data.length !== 0) ? 
				<div className="col-lg-12">
					<div className="row product_details_top">
	        			<div className="col-lg-6">
						{Array.isArray(Data.newImg) ? Data.newImg.map((elem, index=0) => {
							
						return (<>
						{(index == 0) ? 
						
	        				<div className="quick_view_thumb mb-30">
	        					<a data-rel="lightcase:quickView" href="#">
	        						<img src={`${process.env.REACT_APP_API_URL}/assets/Product/${elem}`} alt="img" />
	        					</a>
	        				</div>
						: null }
	        				<div className="quick_view_gallery">
							{(index == 1) ? 
	        					<div className="item" style={{width: "250px"}}>
	        						<a data-rel="lightcase:quickView" href="#">
		        						<img src={`${process.env.REACT_APP_API_URL}/assets/Product/${elem}`} alt="img" />
		        					</a>
	        					</div>
						 : null }
	        					
	        				</div>

							</>)
						{ index++ }
						}) : null }
	        			</div>
	        			<div className="col-lg-6">
	        				<div className="p_details_content">
	        					
								<h3><a href="product-details.html">{Data.p_name}</a></h3>
								<p>{Data.p_description}</p>
								
								
	        				</div>
							<div className="pd-tab-item">
							  			<div className="pd-additional-info table-responsive">
							  				<table className="table table-bordered">
				                            	<tbody>
					                            		<tr>
					                                	<td>Composition</td>
					                                	<td>Classification</td>
														<td>Packing</td>
					                            	</tr>
					                                <tr>
					                                    <td>{Data.p_composition}</td>
					                                    <td>{Data.p_formulation}</td>
														<td>{Data.p_packaging}</td>
					                                </tr>
					                               
					                        	</tbody>
					                        </table>
							  			</div>
							  		</div>
	        			</div>
	        		</div>
	        		
				</div>
				: <h3>Product Not Available</h3> }
			</div>
		</div>
	</section>
	<Footer />
	 </div>
  );
}