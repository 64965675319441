import React from "react";

export default function Footer(){
	
	return (
    <div><footer className="footer">
		<div className="footer-top pt-70 pb-20">
			<div className="container">
				<div className="row">
					<div className="col-lg-3 col-xl-3 col-md-6 mb-30">
						<div className="footer-widgets-single">
							<h3>Our Address</h3>
							<ul className="corporate-address">
								<li>
									<i className="fas fa-map-marker-alt"></i>
									<span>Plot No. 407, HSIIDC, Industrial Area,<br/>
Phase-1, Panchkula-134113</span>
								</li>
								
							</ul>
							
						</div>
					</div>
					<div className="col-lg-3 col-xl-3 col-md-6 mb-30">
						<div className="footer-widgets-single">
							<h3>Contact Us </h3>
							<ul className="corporate-address">
								<li>
									<i className="far fa-envelope"></i>
									<span>sarthilifesciences@gmail.com</span>
								</li>
								<li>
									<i className="far fa-envelope"></i>
									<span>surewinhealthcare@gmail.com</span>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-lg-3 col-xl-3 col-md-6 mb-30">
						<div className="footer-widgets-single">
							<h3>Get In Touch</h3>
							<ul className="corporate-address">
								<li>
									<i className="fas fa-phone-alt"></i>
									<span>+91 98884 63210</span>
								</li><li>
									<i className="fas fa-phone-alt"></i>
									<span>+91 94175 69295</span>
								</li>
								
							</ul>
						</div>
					</div>
					<div className="col-lg-3 col-xl-3 col-md-6 mb-30">
						<div className="footer-widgets-single">
							<h3>Follow Us </h3>
							<div className="footer-social">
								<span><a href="#"><i className="fab fa-facebook-f"></i></a></span>
								<span><a href="#"><i className="fab fa-twitter"></i></a></span>
								<span><a href="#"><i className="fab fa-instagram"></i></a></span>
								<span><a href="#"><i className="fab fa-linkedin"></i></a></span>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>
		
		<div className="footer-bottom pt-30 pb-10">
			<div className="container">
				<div className="row">
					<div className="col-md-6 mb-20">
						<div className="f_caopy_text text-left">
							<p>Copyright © 2023 SarthiLifeScience </p>
						</div>
					</div>
					<div className="col-md-6 mb-20">
						<div className="f_caopy_text text-right">
							<p>IT:Partner <a href="#"><b>LeadManch.com</b></a></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
	<div className="scroll-area">
       <i className="bi bi-arrow-up"></i>
    </div>
	 </div>
  );
}