import Home from "./component/front/Home";
import ProductLisitng from "./component/front/ProductListing";
import Product from "./component/front/Product";
import About from "./component/front/About";
import Blog from "./component/front/Blog";
import BlogListing from "./component/front/BlogListing";
import Contact from "./component/front/Contact";
import ProductCat from "./component/front/ProductCat";
import Pcd from "./component/front/Pcd";
import Tpm from "./component/front/Tpm";
import { BrowserRouter as Router,Routes, Route } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import { useState} from "react";

function App() {
  const [progress, setProgress] = useState(0)
  return (
    <div className="App">
     <Router>
     <Routes>
     
	  <Route  exact path="/" element={<Home />} />
	  <Route exact path="/product-list/" element={<Product />} />
    <Route exact path="/product/:productcat" element={<ProductCat />} />
    <Route exact path="/product-detail/:product" element={<ProductLisitng />} />
    <Route  exact path="/about-us" element={<About />} />
    <Route  exact path="/blog" element={<Blog/>} />
    <Route exact path="/blog/:blog" element={<BlogListing />} /> 
    <Route exact path="/contact-us" element={<Contact />} /> 
    <Route exact path="/third-party-manufacturing" element={<Tpm />} /> 
    <Route exact path="/pharma-pcd" element={<Pcd />} /> 
</Routes>
	  
	</Router>
    </div>
  );
}

export default App;
