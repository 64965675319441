import React from "react";
import Header from "./template/Header";
import Footer from "./template/Footer";
import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
export default function Blog(){
	const ApiUrl = process.env.REACT_APP_API_URL;
	const [Data, setData] = useState([]);
	const getpdata = async (e) => {
		await fetch(ApiUrl+"GetAllBlog", {
		  method: "GET",
		  mode:'cors'
		}).then(res => res.json()).then(data => {
		  setData(data.data);
			})
	  };
	  useEffect(() => {
		getpdata();
		 }, []);
		 
	return (
    <div>
	<Header/>
    <section className="section-padding">
		<div className="container">
			<div className="row">
            {Data.length ? Data.map((elem) => {
                return (<>
				<div className="col-lg-4 col-md-6 mb-30">
					<div className="blog-item">
						<div className="thumbnail">
							<Link to={`${process.env.REACT_APP_FRONT_URL}blog/${elem.seo_url}`}>
								<img src={`${process.env.REACT_APP_API_URL}/assets/Blog/${elem.img}`} alt="blog" />
							</Link>
							<div className="date">
								<span><i className="fas fa-calendar-alt"></i> {elem.created}</span>
							</div>
						</div>
						<div className="content">
							<h2><Link to={`${process.env.REACT_APP_FRONT_URL}blog/${elem.seo_url}`}>{elem.title}</Link></h2>
							
						</div>
					</div>
				</div>
                </>
                )
            }) : <span>Please wait..............</span> }
				
			</div>
			
		</div>
	</section>
	<Footer />
	 </div>
  );
}