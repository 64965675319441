import React from "react";
import Header from "./template/Header";
import Footer from "./template/Footer";
import {useParams} from 'react-router-dom';
import { useState,useEffect } from "react";
export default function BlogListing(){
	const {blog} = useParams();
	const ApiUrl = process.env.REACT_APP_API_URL;
	const [Data, setData] = useState([]);
	const getpdata = async (e) => {
		await fetch(ApiUrl+"GetAllBlog/"+blog, {
		  method: "GET",
		  mode:'cors'
		}).then(res => res.json()).then(data => {
		  setData(data.data);
			})
	  };
	  useEffect(() => {
		getpdata();
		 }, []);
	console.log(Data)
	return (
    <div>
	<Header/>
    <section className="section-padding">
		<div className="container">
			<div className="row">
				<div className="col-lg-12">
               {(Data !== null) ?
					<div className="blog-details mb-30">
						<div className="thumbnail">
							<img src={`${process.env.REACT_APP_API_URL}/assets/Blog/${Data.img}`} alt="img" />
						</div>
						<div className="content">
							<div className="meta">
								<span><i className="far fa-calendar-alt"></i>{Data.created}</span>
								<span><i className="fas fa-tags"></i> <a href="#">Business</a></span>
							</div>
							<h2>{Data.title}</h2>
							<p className="mt-1" dangerouslySetInnerHTML={{__html: Data.description+'...'}}></p>
							
							
							
						</div>
					</div>
               : null }
					
				</div>
				
			</div>
		</div>
	</section>
	<Footer />
	 </div>
  );
}